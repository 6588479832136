import api from 'src/utils/api/core'

export const FEATURE_FLAG_KEY = 'feature_flags'

export interface IPreferencesFeatureFlags {
  id?: number
  key: 'feature_flags'
  createdAt?: string
  updatedAt?: string
  deletedAt?: string
  port?: any
  uuid?: string
  metadata: {
    pilot_create_pilotage_disabled?: boolean
    manual_online_enabled?: boolean
    no_tugs_exclude_in_pdf_enabled?: boolean
    residual_diff_enabled?: boolean
    residual_diff_ignore_time_enabled?: boolean
    active_default_enabled?: boolean
    hide_waypoint_location_enabled?: boolean
    show_timestamps_in_master_view?: boolean
    show_checklist_in_master_view?: boolean
    show_passfail_in_master_view?: boolean
    use_e_signatures?: boolean
    use_berthing_conditions?: boolean
    use_chart_tools_grid?: boolean
    omc_config?: {
      system_name: string
      show_graph_in_master_view?: boolean
      show_table_in_master_view?: boolean
    }
    tide_rate_source?: 'config' | 'csv'
  }
}

const defaultFeatureFlags: IPreferencesFeatureFlags = {
  key: 'feature_flags',
  metadata: {
    pilot_create_pilotage_disabled: false,
    manual_online_enabled: false,
    show_checklist_in_master_view: true,
    show_passfail_in_master_view: true,
    active_default_enabled: false,
    hide_waypoint_location_enabled: false,
    show_timestamps_in_master_view: false,
    use_e_signatures: false,
    use_berthing_conditions: false,
    use_chart_tools_grid: true,
    omc_config: {
        system_name: '',
        show_graph_in_master_view: true,
        show_table_in_master_view: true
    },
    tide_rate_source: 'config'
  }
}

export const TIDAL_RATE_CONFIG_KEY = 'tidal_rate_config'

export enum TideFlowDirection {
  Flood = 'Flood',
  Ebb = 'Ebb'
}

// Nice explanation of Neap/Spring tides: https://oceanservice.noaa.gov/facts/springtide.html
export enum TideType {
  Neap = 'Neap',
  Spring = 'Spring'
}

export interface TideExtreme {
  tide: number
  dateTime: number
}

export interface TidalRange {
    term: string
    knotsLower: number
    knotsUpper: number
}

export interface TidalRanges {
    [uuid: string]: TidalRange
}

export interface TidalRate {
    hoursOffset: number // -6 .. 6
    neapRangeUuid: string
    springRangeUuid: string
    directionDegrees?: number
}

export interface TidalRateCalculation {
  tideType: TideType
  tideDirection: TideFlowDirection
  highWaterOffset: number
  range: TidalRange
  degrees?: number
}

export interface TidalRates {
    [uuid: string]: TidalRate
}

export interface TidalRateConfig {
    enabled: boolean
    tideTypeRangeThreshold: number
    ranges: TidalRanges
    rates: TidalRates
}

export interface IPreferencesTidalRateConfig {
    id?: number
    key: 'tidal_rate_config'
    createdAt?: string
    updatedAt?: string
    deletedAt?: string
    port?: any
    uuid?: string
    metadata: TidalRateConfig
}


export const VESSEL_FIELD_DISPLAY_CONFIG_KEY = 'vessel_field_display_config'

export const defaultDisabledVesselFieldsPilotApp = () => ({
  'astern_pwr': true,
  'full_hbr': true,
  'squat_full_hbr': true,
  'half_ahead': true,
  'slow_ahead': true,
  'dead_slow': true,
  'sea_speed': true,
  'net_tonnage': true,
  'prop_effect': true,
  'me_power_limitation': true,
  'me_limited_power': true,
  'me_limiter_immediately_overridable': true
})

export const defaultDisabledVesselFieldsMasterView = () => ({
  'comments': true,
  'emails': true,
  'vesselType': true,
  'year_built': true,
  'DWT_summer': true,
  'draft_summer': true,
  'call_sign': true,
  'prop_no': true,
  'prop_azi': true,
  'sternToAftContBay': true,
  'beamToFwdContBay': true,
  'astern_pwr': true,
  'full_hbr': true,
  'squat_full_hbr': true,
  'half_ahead': true,
  'slow_ahead': true,
  'dead_slow': true,
  'sea_speed': true,
  'net_tonnage': true,
  'prop_effect': true,
  'me_power_limitation': true,
  'me_limited_power': true,
  'me_limiter_immediately_overridable': true
})

export interface VesselFieldDisplayConfig {
  disabled_pilot_app: { [field_name: string]: boolean }
  disabled_master_view: { [field_name: string]: boolean }
}

export interface IPreferencesVesselFieldDisplayConfig {
  id?: number
  key: 'vessel_field_display_config'
  createdAt?: string
  updatedAt?: string
  deletedAt?: string
  port?: any
  uuid?: string
  metadata: VesselFieldDisplayConfig
}

export const defaultVesselFieldDisplayConfig: IPreferencesVesselFieldDisplayConfig = {
  uuid: '', // no uuid means it doesn't exist in db yet
  key: VESSEL_FIELD_DISPLAY_CONFIG_KEY,
  metadata: {
      disabled_pilot_app: defaultDisabledVesselFieldsPilotApp(),
      disabled_master_view: defaultDisabledVesselFieldsMasterView()
  }
}



export type IPreferences
  = IPreferencesFeatureFlags
  | IPreferencesTidalRateConfig
  | IPreferencesVesselFieldDisplayConfig

function extendPreferencesWithDefaults(preferences: IPreferences[]): IPreferences[] {

  const featureFlags = preferences.find(
    ({ key }) => key === FEATURE_FLAG_KEY
  ) as IPreferencesFeatureFlags | undefined

  const tidalRateConfig = preferences.find(
    ({ key }) => key === TIDAL_RATE_CONFIG_KEY
  ) as IPreferencesTidalRateConfig | undefined

  const vesselFieldDisplayConfig = preferences.find(
    ({ key }) => key === VESSEL_FIELD_DISPLAY_CONFIG_KEY
  ) as IPreferencesVesselFieldDisplayConfig | undefined

  const extended: IPreferences[] = [
    featureFlags || defaultFeatureFlags,
    vesselFieldDisplayConfig || defaultVesselFieldDisplayConfig,
  ]

  if (tidalRateConfig) {
    extended.push(tidalRateConfig)
  }

  return extended
}


export async function getPreferences(portUuid: string) {
  // EMPX-684: do not eat error, but allow redux to hold data
  // as null, so we can tell if preferences have been successfully
  // loaded from BE. this is needed bcos some features
  // might rely on the correct preferences settings to function
  // correctly, eg manual offline.
  const res = await api.get<{ data: IPreferences[] }>(
    `/preferences`, { params: { portUuid } }
  )
  const preferences = res.data && res.data.data
  return extendPreferencesWithDefaults(preferences || [])
}
