import React, { useMemo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import {
  Box,
  Grid,
  LinearProgress,
  Typography,
} from '@material-ui/core'
import { Field, Form, Formik } from 'formik'
import isEqual from 'fast-deep-equal'
import idx from 'idx'

import TextInput from 'src/components/atoms/TextInput'
import FreeTextField from 'src/components/atoms/FreeTextField'
import PrimaryButton from 'src/components/atoms/PrimaryButton'
import Select from 'src/components/atoms/Select'
import {
  vesselPropTypeOptions,
  vesselPropNoOptions,
  vesselTypeOptions,
  vesselPropPitchOptions,
  vesselPropEffectOptions,
  vesselPropRotationOptions,
  vesselRudderTypeOptions,
  vesselTrackControlOptions,
  ENTITY_NAME,
  NOT_KNOWN, vesselMEPowerLimitationOptions, vesselMELimiterImmediatelyOverridableOptions,
} from 'src/utils/constants'
import { vesselSelector } from 'src/store/vessel/selectors'
import {
  isAssignedToActivePilotageSelector,
  isPilotageDoneSelector,
  isPilotageArchivedSelector,
  isPilotageCancelledSelector,
  pilotageUuidSelector,
  pilotageSelector,
} from 'src/store/pilotage/selectors'
import { formatIfNumber, kwToHp, roundTwoDecimalPlace } from 'src/utils/formatters'
import vesselValidator from 'src/utils/validators/vesselValidator'
import { updateVesselRequest } from 'src/store/vessel/actions'
import FormikObserver from 'src/components/atoms/FormikObserver/FormikObserver'
import { setUnsavedEntityStatus } from 'src/store/ui/actions'
import { unsavedVesselSelector } from 'src/store/ui/selectors'
import { selectedPortUuidSelector } from 'src/store/ports/selectors'
import Tooltip from 'src/components/atoms/Tooltip'
import TOOLTIPS from 'src/utils/tooltips'
import { pilotageRequest } from 'src/store/pilotage/actions'
import { vesselFieldDisplayConfigSelector } from 'src/store/preferences/selectors'

const CommentLabel = styled.label(
  ({ theme }) => `
  && {
    display: flex;
    text-transform: uppercase;
    color: ${theme.palette.text.secondary};
    margin-bottom: 5px;
    & :first-child {
      margin-right: ${theme.spacing(1)}px;
    }
  }
`
)

const ErrorSummary = styled.div`
  color: ${({ theme }) => theme.palette.error.light};
  padding: 10px 20px;
  font-size: 12px;
`

const Fieldset = styled(Grid)`
  &:not(:empty) {
    border-bottom: 1px solid #33415f;
    padding-bottom: 15px;
    margin-bottom: 20px;
  }
`

const EditVesselDetails = () => {
  const dispatch = useDispatch()
  const portUuid = useSelector(selectedPortUuidSelector)
  const { error: pilotageError } = useSelector(pilotageSelector)
  const { data, isLoading, error } = useSelector(vesselSelector)
  const isAssigned = useSelector(isAssignedToActivePilotageSelector)
  const isPilotageDone = useSelector(isPilotageDoneSelector)
  const isPilotageArchived = useSelector(isPilotageArchivedSelector)
  const isPilotageCancelled = useSelector(isPilotageCancelledSelector)
  const hasUnsavedChanges = useSelector(unsavedVesselSelector)
  const selectedPilotageUuid = useSelector(pilotageUuidSelector)
  const vesselFieldDisplayConfig = useSelector(vesselFieldDisplayConfigSelector)

  const fieldEnabled = (fieldName) =>
    !vesselFieldDisplayConfig ||
    !vesselFieldDisplayConfig.disabled_pilot_app[fieldName.replace(/^metadata_/, '')]

  const isReadOnly =
    !isAssigned || isPilotageDone || isPilotageArchived || isPilotageCancelled

  const formInitialState = useMemo(
    () => {

      const trackControl = idx(data, d => d.trackControl)

      return {
        IMO: idx(data, d => d.IMO) || '',
        name: idx(data, d => d.name) || '',
        vesselType: idx(data, d => d.vesselType) || '',
        email: idx(data, d => d.emails.join(', ')) || '',
        metadata_master_name: idx(data, d => d.metadata.master_name) || '',
        length: formatIfNumber(idx(data, d => d.length), 2, ''),
        height: formatIfNumber(idx(data, d => d.height), 2, ''),
        beam: formatIfNumber(idx(data, d => d.beam), 2, ''),
        bridgeToStern: formatIfNumber(idx(data, d => d.bridgeToStern), 2, ''),
        bridgeToBow: formatIfNumber(idx(data, d => d.bridgeToBow), 2, ''),
        bittsFwd: formatIfNumber(idx(data, d => d.bittsFwd), 2, ''),
        bittsAft: formatIfNumber(idx(data, d => d.bittsAft), 2, ''),
        trackControl: typeof trackControl === 'boolean' ? String(trackControl) : NOT_KNOWN,
        metadata_air_draft: formatIfNumber(idx(data, d => d.metadata.air_draft),2, ''),
        metadata_sternToAftContBay: formatIfNumber(idx(data, d => d.metadata.sternToAftContBay), 2, ''),
        metadata_beamToFwdContBay: formatIfNumber(idx(data, d => d.metadata.beamToFwdContBay), 2, ''),
        metadata_year_built: idx(data, d => d.metadata.year_built) || '',
        metadata_DWT_summer: formatIfNumber(idx(data, d => d.metadata.DWT_summer), 2, ''),
        metadata_draft_summer: formatIfNumber(idx(data, d => d.metadata.draft_summer), 2, ''),
        metadata_call_sign: idx(data, d => d.metadata.call_sign) || '',
        metadata_mmsi: idx(data, d => d.metadata.mmsi) || '',
        metadata_gross_tonnage: formatIfNumber(idx(data, d => d.metadata.gross_tonnage), 2, ''),
        metadata_net_tonnage: formatIfNumber(idx(data, d => d.metadata.net_tonnage), 2, ''),
        metadata_prop_no: idx(data, d => d.metadata.prop_no) || '',
        metadata_cpp_fixed: idx(data, d => d.metadata.cpp_fixed) || '',
        metadata_prop_azi: idx(data, d => d.metadata.prop_azi) || '',
        metadata_rotation: idx(data, d => d.metadata.rotation) || '',
        metadata_prop_effect: idx(data, d => d.metadata.prop_effect) || '',
        metadata_rudder: idx(data, d => d.metadata.rudder) || '',
        metadata_astern_pwr: formatIfNumber(idx(data, d => d.metadata.astern_pwr), 2, ''),
        metadata_full_hbr: formatIfNumber(idx(data, d => d.metadata.full_hbr), 2, ''),
        metadata_squat_full_hbr: formatIfNumber(idx(data, d => d.metadata.squat_full_hbr), 2, ''),
        metadata_half_ahead: formatIfNumber(idx(data, d => d.metadata.half_ahead), 2, ''),
        metadata_slow_ahead: formatIfNumber(idx(data, d => d.metadata.slow_ahead), 2, ''),
        metadata_dead_slow: formatIfNumber(idx(data, d => d.metadata.dead_slow), 2, ''),
        metadata_sea_speed: formatIfNumber(idx(data, d => d.metadata.sea_speed), 2, ''),
        metadata_vessel_depth: formatIfNumber(idx(data, d => d.metadata.vessel_depth), 2, ''),
        metadata_thr_fwd: formatIfNumber(idx(data, d => d.metadata.thr_fwd), 2, ''),
        metadata_thr_aft: formatIfNumber(idx(data, d => d.metadata.thr_aft), 2, ''),
        metadata_main_engine: formatIfNumber(idx(data, d => d.metadata.main_engine), 2, ''),
        metadata_comments: idx(data, d => d.metadata.comments) || '',
        metadata_me_power_limitation: idx(data, d => d.metadata.me_power_limitation) || '',
        metadata_me_limited_power: formatIfNumber(idx(data, d => d.metadata.me_limited_power), 2, ''),
        metadata_me_limiter_immediately_overridable: idx(data, d => d.metadata.me_limiter_immediately_overridable) || '',
      }
    },
    [data]
  )

  const getPropsForAutoformatOnBlur = useMemo(
    () => ({ fieldName, setFieldValue, setFieldTouched, dp }) => {
      return {
        onChange: value => setFieldValue(fieldName, value),
        onBlur: e => {
          setFieldTouched(fieldName)
          if (e.target.value && !isNaN(Number(e.target.value))) {
            setFieldValue(fieldName, Number(e.target.value).toFixed(dp))
          }
        },
      }
    },
    []
  )

  const existing = data

  const onSubmit = async (values, actions) => {
    actions.setSubmitting(true)

    let bridgeToBowOverride = values.bridgeToBow
    let lengthOverride = values.length
    let bridgeToSternOverride = values.bridgeToStern
    if (values.length && values.bridgeToStern && !values.bridgeToBow) {
      const bridgeToBowOverrideTmp = Number(values.length) - Number(values.bridgeToStern)
      // Only set if outcome is +ve in value
      if (bridgeToBowOverrideTmp > 0) {
        bridgeToBowOverride = bridgeToBowOverrideTmp
      }
    } else if (!values.length && values.bridgeToStern && values.bridgeToBow) {
      lengthOverride = Number(values.bridgeToStern) + Number(values.bridgeToBow)
    } else if (values.length && !values.bridgeToStern && values.bridgeToBow) {
      const bridgeToSternOverrideTmp = Number(values.length) - Number(values.bridgeToBow)
      // Only set if outcome is +ve in value
      if (bridgeToSternOverrideTmp > 0) {
        bridgeToSternOverride = bridgeToSternOverrideTmp
      }
    }

    const data = {
      vesselType: values.vesselType || null,
      name: values.name,
      emails: values.email
        .split(',')
        .map(email => email.trim())
        .filter(email => !!email),
      length: lengthOverride ? Number(lengthOverride) : null,
      height: values.height ? Number(values.height) : null,
      beam: values.beam ? Number(values.beam) : null,
      bridgeToStern: bridgeToSternOverride ? Number(bridgeToSternOverride) : null,
      bridgeToBow: bridgeToBowOverride ? Number(bridgeToBowOverride) : null,
      bittsFwd: values.bittsFwd ? Number(values.bittsFwd) : null,
      bittsAft: values.bittsAft ? Number(values.bittsAft) : null,
      trackControl: values.trackControl === NOT_KNOWN ? null : JSON.parse(values.trackControl),
      metadata: {
        ...existing.metadata
      },
      // add the port to update the vessel against the selected port
      // otherwise admins will update the vessel globaly
      port: {
        uuid: portUuid,
      },
    }

    Object.keys(values).forEach(key => {
      if (/^metadata_/.test(key)) {
        const metadataKey = key.replace(/^metadata_/, '')
        data.metadata[metadataKey] = values[key]
      }
    })

    const updateSuccessful = await dispatch(
      updateVesselRequest(values.IMO, data)
    )
    actions.setSubmitting(false)
    // EMPX-97 on vessel update, pull pilotage to reflect new name
    // in InfoBarHeader. NOTE: In Offline, this will not work, but
    // it is OK, as Pilotage Form does not allow vessel name editing
    // and Pilotage List will also show old name in Offline. The only
    // place that shows the changed name is in this form.
    // BUG: apparently this can trigger a Changed Pilotages form to
    // appear, revert for now until further investigation.
    // Fixed in EMPX-313.
    if (selectedPilotageUuid) {
      dispatch(pilotageRequest(selectedPilotageUuid))
    }

    if (updateSuccessful) {
      window.history.back()
    }
  }

  const onFormChange = values => {
    const hasChanged = !isEqual(values, formInitialState)
    dispatch(setUnsavedEntityStatus(ENTITY_NAME.VESSEL, hasChanged))
  }

  useEffect(() => {
    return () => {
      dispatch(setUnsavedEntityStatus(ENTITY_NAME.VESSEL, false))
    }
  }, [])

  const isPositive = n => {
    const float = parseFloat(n)
    return !isNaN(float) && float > 0
  }

  if (!data && !error && !pilotageError) {
    return <LinearProgress variant="indeterminate" />
  }

  if (!data && (error || pilotageError)) {
    return (
      <Typography variant="body1">
        Unable to load vessel data. Please try again later.
      </Typography>
    )
  }

  return (
    <Formik
      enableReinitialize
      initialValues={formInitialState}
      validate={vesselValidator}
      onSubmit={onSubmit}
    >
      {({
        values,
        errors,
        isSubmitting,
        setFieldTouched,
        setFieldValue,
        handleChange,
      }) => {

        const errorCount = Object.keys(errors).length
        const hasErrors = errorCount > 0
        const isContainerShip = values.vesselType === 'Container'

        const flagCode = idx(data, d => d.metadata.flag_code)

        return (
          <Form>
            <FormikObserver values={values} onChange={onFormChange} />
            <Fieldset container spacing={2} style={{alignItems: 'end'}}>
              {
                fieldEnabled('name') &&
                <Grid item xs={6}>
                  <Field
                    name="name"
                    render={({ field }) => (
                      <TextInput
                        id="vessel-name"
                        label="Vessel Name"
                        formikField={field}
                        error={errors.name}
                        disabled={isLoading}
                        readOnly={isReadOnly}
                        useMissingPlaceholder
                      />
                    )}
                  />
                </Grid>
              }
              {
                fieldEnabled('IMO') &&
                <Grid item xs={6}>
                  <Field
                    name="IMO"
                    render={({ field }) => (
                      <TextInput
                        id="vessel-imo"
                        label={
                          <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <span style={{ paddingRight: 8 }}>Vessel IMO</span>
                            {flagCode && <span className={`flag-icon flag-icon-${flagCode.toLowerCase()}`}></span>}
                          </div>
                        }
                        formikField={field}
                        readOnly
                        disabled={isLoading}
                        useMissingPlaceholder
                        inputProps={{ inputMode: 'numeric' }}
                      />
                    )}
                  />
                </Grid>
              }
              {
                fieldEnabled('vesselType') &&
                <Grid item xs={6}>
                  <Select
                    id="vessel-type"
                    label="Vessel Type"
                    value={values.vesselType}
                    onClose={() => setFieldTouched('vesselType')}
                    onChange={handleChange('vesselType')}
                    options={vesselTypeOptions}
                    error={errors.vesselType}
                    disabled={isLoading}
                    readOnly={isReadOnly}
                  />
                </Grid>
              }
              {
                <Grid item xs={6}>
                  <Field
                    name="metadata_master_name"
                    render={({ field }) => (
                      <TextInput
                        id="vessel-master-name"
                        label="Master's Name"
                        formikField={field}
                        error={errors.metadata_master_name}
                        disabled={isLoading}
                        readOnly={isReadOnly}
                        useMissingPlaceholder
                      />
                    )}
                  />
                </Grid>
              }
              {
                fieldEnabled('email') &&
                <Grid item xs={12}>
                  <Field
                    name="email"
                    render={({ field }) => (
                      <TextInput
                        id="vessel-email"
                        label={
                          <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <span style={{ paddingRight: 8 }}>Vessel Email(s)</span>
                            <Tooltip content={TOOLTIPS.VESSEL_EMAILS} />
                          </div>
                        }
                        formikField={field}
                        error={errors.email}
                        disabled={isLoading}
                        readOnly={isReadOnly}
                        useMissingPlaceholder
                      />
                    )}
                  />
                </Grid>
              }
            </Fieldset>

            <Fieldset container spacing={2} style={{alignItems: 'end'}}>
              {
                fieldEnabled('length') &&
                <Grid item xs={4} style={{marginTop: '15px', position: 'relative'}}>
                  <TextInput 
                    errorFieldAbsolute
                    id="vessel-loa"
                    label="LOA"
                    unit="m"
                    value={values.length}
                    error={errors.length}
                    useMissingPlaceholder
                    disabled={isLoading}
                    readOnly={isReadOnly}
                    {...getPropsForAutoformatOnBlur({
                      fieldName: 'length',
                      dp: 2,
                      setFieldValue,
                      setFieldTouched,
                    })}
                    inputProps={{ inputMode: 'decimal' }}
                  />
                </Grid>
              }

              {
                fieldEnabled('beam') &&
                <Grid item xs={4} style={{marginTop: '15px', position: 'relative'}}>
                  <TextInput
                    errorFieldAbsolute
                    id="vessel-beam"
                    label="Beam"
                    unit="m"
                    value={values.beam}
                    error={errors.beam}
                    useMissingPlaceholder
                    disabled={isLoading}
                    readOnly={isReadOnly}
                    {...getPropsForAutoformatOnBlur({
                      fieldName: 'beam',
                      dp: 2,
                      setFieldValue,
                      setFieldTouched,
                    })}
                    inputProps={{ inputMode: 'decimal' }}
                  />
                </Grid>
              }
              {
                fieldEnabled('metadata_draft_summer') &&
                <Grid item xs={4} style={{marginTop: '15px', position: 'relative'}}>
                  <TextInput
                    errorFieldAbsolute
                    id="vessel-draft"
                    label="Draft (Summer)"
                    unit="m"
                    value={values.metadata_draft_summer}
                    error={errors.metadata_draft_summer}
                    useMissingPlaceholder
                    disabled={isLoading}
                    readOnly={isReadOnly}
                    {...getPropsForAutoformatOnBlur({
                      fieldName: 'metadata_draft_summer',
                      dp: 2,
                      setFieldValue,
                      setFieldTouched,
                    })}
                    inputProps={{ inputMode: 'decimal' }}
                  />
                </Grid>
              }

              {
                fieldEnabled('height') &&
                <Grid item xs={4} style={{marginTop: '15px', position: 'relative'}}>
                  <TextInput
                    errorFieldAbsolute
                    id="vessel-hoa"
                    label="HOA"
                    unit="m"
                    value={values.height}
                    error={errors.height}
                    useMissingPlaceholder
                    disabled={isLoading}
                    readOnly={isReadOnly}
                    {...getPropsForAutoformatOnBlur({
                      fieldName: 'height',
                      dp: 2,
                      setFieldValue,
                      setFieldTouched,
                    })}
                    inputProps={{ inputMode: 'decimal' }}
                  />
                </Grid>
              }

              {
                fieldEnabled('metadata_vessel_depth') &&
                <Grid item xs={4} style={{marginTop: '15px', position: 'relative'}}>
                  <TextInput
                    errorFieldAbsolute
                    id="vessel-depth"
                    label="Vessel Depth"
                    unit="m"
                    value={values.metadata_vessel_depth}
                    error={
                      errors.metadata_vessel_depth
                    }
                    useMissingPlaceholder
                    disabled={isLoading}
                    readOnly={isReadOnly}
                    {...getPropsForAutoformatOnBlur({
                      fieldName: 'metadata_vessel_depth',
                      dp: 2,
                      setFieldValue,
                      setFieldTouched,
                    })}
                    inputProps={{ inputMode: 'decimal' }}
                  />
                </Grid>
              }

              {
                fieldEnabled('metadata_air_draft') &&
                <Grid item xs={4} style={{marginTop: '15px', position: 'relative'}}>
                  <TextInput
                    errorFieldAbsolute
                    id="vessel-air-draft"
                    label="Air Draft"
                    unit="m"
                    value={values.metadata_air_draft}
                    error={errors.metadata_air_draft}
                    useMissingPlaceholder
                    disabled={isLoading}
                    readOnly={isReadOnly}
                    {...getPropsForAutoformatOnBlur({
                      fieldName: 'metadata_air_draft',
                      dp: 2,
                      setFieldValue,
                      setFieldTouched,
                    })}
                    inputProps={{ inputMode: 'decimal' }}
                  />
                </Grid>
              }

              {
                fieldEnabled('bridgeToStern') &&
                <Grid item xs={4} style={{marginTop: '15px', position: 'relative'}}>
                  <TextInput
                    errorFieldAbsolute
                    id="vessel-stern-to-bridge"
                    label="S. To Bridge"
                    unit="m"
                    value={values.bridgeToStern}
                    error={errors.bridgeToStern}
                    useMissingPlaceholder
                    disabled={isLoading}
                    readOnly={isReadOnly}
                    {...getPropsForAutoformatOnBlur({
                      fieldName: 'bridgeToStern',
                      dp: 2,
                      setFieldValue,
                      setFieldTouched,
                    })}
                    inputProps={{ inputMode: 'decimal' }}
                  />
                </Grid>
              }
              {
                fieldEnabled('bridgeToBow') &&
                <Grid item xs={4} style={{marginTop: '15px', position: 'relative'}}>
                  <TextInput
                    errorFieldAbsolute
                    id="vessel-bow-to-bridge"
                    label="B. To Bridge"
                    unit="m"
                    value={values.bridgeToBow}
                    error={errors.bridgeToBow}
                    useMissingPlaceholder
                    disabled={isLoading}
                    readOnly={isReadOnly}
                    {...getPropsForAutoformatOnBlur({
                      fieldName: 'bridgeToBow',
                      dp: 2,
                      setFieldValue,
                      setFieldTouched,
                    })}
                    inputProps={{ inputMode: 'decimal' }}
                  />
                </Grid>
              }

              {
                isContainerShip && fieldEnabled('metadata_sternToAftContBay') &&
                <Grid item xs={4} style={{marginTop: '15px', position: 'relative'}}>
                  <TextInput
                    errorFieldAbsolute
                    id="metadata-stern-to-aft-cont-bay"
                    label="S.-Aft Cont.B."
                    unit="m"
                    value={values.metadata_sternToAftContBay}
                    error={
                      errors.metadata_sternToAftContBay
                    }
                    useMissingPlaceholder
                    disabled={isLoading}
                    readOnly={isReadOnly}
                    {...getPropsForAutoformatOnBlur({
                      fieldName: 'metadata_sternToAftContBay',
                      dp: 2,
                      setFieldValue,
                      setFieldTouched,
                    })}
                    inputProps={{ inputMode: 'decimal' }}
                  />
                </Grid>
              }
              {
                isContainerShip && fieldEnabled('metadata_beamToFwdContBay') &&
                <Grid item xs={4} style={{marginTop: '15px', position: 'relative'}}>
                  <TextInput
                    errorFieldAbsolute
                    id="metadata-beam-to-fwd-cont-bay"
                    label="B.-Fwd Cont.B."
                    unit="m"
                    value={values.metadata_beamToFwdContBay}
                    error={
                      errors.metadata_beamToFwdContBay
                    }
                    useMissingPlaceholder
                    disabled={isLoading}
                    readOnly={isReadOnly}
                    {...getPropsForAutoformatOnBlur({
                      fieldName: 'metadata_beamToFwdContBay',
                      dp: 2,
                      setFieldValue,
                      setFieldTouched,
                    })}
                    inputProps={{ inputMode: 'decimal' }}
                  />
                </Grid>
              }

              {
                fieldEnabled('metadata_gross_tonnage') &&
                <Grid item xs={4} style={{marginTop: '15px', position: 'relative'}}>
                  <TextInput
                    errorFieldAbsolute
                    id="vessel-gross-tonnage"
                    label="Gross Tonnage"
                    unit="t"
                    value={values.metadata_gross_tonnage}
                    error={
                      errors.metadata_gross_tonnage
                    }
                    useMissingPlaceholder
                    disabled={isLoading}
                    readOnly={isReadOnly}
                    {...getPropsForAutoformatOnBlur({
                      fieldName: 'metadata_gross_tonnage',
                      dp: 2,
                      setFieldValue,
                      setFieldTouched,
                    })}
                    inputProps={{ inputMode: 'decimal' }}
                  />
                </Grid>
              }

              {
                fieldEnabled('metadata_net_tonnage') &&
                <Grid item xs={4} style={{marginTop: '15px', position: 'relative'}}>
                  <TextInput
                    errorFieldAbsolute
                    id="vessel-net-tonnage"
                    label="Net Tonnage"
                    unit="t"
                    value={values.metadata_net_tonnage}
                    error={
                      errors.metadata_net_tonnage
                    }
                    useMissingPlaceholder
                    disabled={isLoading}
                    readOnly={isReadOnly}
                    {...getPropsForAutoformatOnBlur({
                      fieldName: 'metadata_net_tonnage',
                      dp: 2,
                      setFieldValue,
                      setFieldTouched,
                    })}
                    inputProps={{ inputMode: 'decimal' }}
                  />
                </Grid>
              }

              {
                fieldEnabled('metadata_DWT_summer') &&
                <Grid item xs={4} style={{marginTop: '15px', position: 'relative'}}>
                  <TextInput
                    errorFieldAbsolute
                    id="vessel-deadweight"
                    label="DWT (Summer)"
                    unit="t"
                    value={values.metadata_DWT_summer}
                    error={
                      errors.metadata_DWT_summer
                    }
                    useMissingPlaceholder
                    disabled={isLoading}
                    readOnly={isReadOnly}
                    {...getPropsForAutoformatOnBlur({
                      fieldName: 'metadata_DWT_summer',
                      dp: 2,
                      setFieldValue,
                      setFieldTouched,
                    })}
                    inputProps={{ inputMode: 'decimal' }}
                  />
                </Grid>
              }
            </Fieldset>

            <Fieldset container spacing={2} style={{alignItems: 'end'}}>

              {
                fieldEnabled('metadata_prop_no') &&
                <Grid item xs={4} style={{marginTop: '15px', position: 'relative'}}>
                  <Select
                    id="vessel-propellor-no"
                    label="Propeller No"
                    value={values.metadata_prop_no}
                    onClose={() => setFieldTouched('metadata_prop_no')}
                    onChange={handleChange('metadata_prop_no')}
                    options={vesselPropNoOptions}
                    error={errors.metadata_prop_no}
                    disabled={isLoading}
                    readOnly={isReadOnly}
                  />
                </Grid>
              }
              {
                fieldEnabled('metadata_cpp_fixed') &&
                <Grid item xs={4} style={{marginTop: '15px', position: 'relative'}}>
                  <Select
                    id="vessel-propellor-pitch"
                    label="CPP/Fixed"
                    value={values.metadata_cpp_fixed}
                    onClose={() => setFieldTouched('metadata_cpp_fixed')}
                    onChange={handleChange('metadata_cpp_fixed')}
                    options={vesselPropPitchOptions}
                    error={errors.metadata_cpp_fixed}
                    disabled={isLoading}
                    readOnly={isReadOnly}
                  />
                </Grid>
              }
              {
                fieldEnabled('metadata_prop_effect') &&
                <Grid item xs={4} style={{marginTop: '15px', position: 'relative'}}>
                  <Select
                    id="vessel-propellor-effect"
                    label={
                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <span style={{ whiteSpace: 'nowrap' }}>Prop Effect</span>
                        <Tooltip content={TOOLTIPS.VESSEL_PROP_EFFECT} />
                      </div>
                    }
                    value={values.metadata_prop_effect}
                    onClose={() => setFieldTouched('metadata_prop_effect')}
                    onChange={handleChange('metadata_prop_effect')}
                    options={vesselPropEffectOptions}
                    error={errors.metadata_prop_effect}
                    disabled={isLoading}
                    readOnly={isReadOnly}
                  />
                </Grid>
              }
              {
                fieldEnabled('metadata_rotation') &&
                <Grid item xs={4} style={{marginTop: '15px', position: 'relative'}}>
                  <Select
                    id="vessel-propellor-rotation"
                    label="Rotation"
                    value={values.metadata_rotation}
                    onClose={() => setFieldTouched('metadata_rotation')}
                    onChange={handleChange('metadata_rotation')}
                    options={vesselPropRotationOptions}
                    error={errors.metadata_rotation}
                    disabled={isLoading}
                    readOnly={isReadOnly}
                  />
                </Grid>
              }
              {
                fieldEnabled('metadata_prop_azi') &&
                <Grid item xs={4} style={{marginTop: '15px', position: 'relative'}}>
                  <Select
                    id="vessel-propellor-type"
                    label="Prop/Azi"
                    value={values.metadata_prop_azi}
                    onClose={() => setFieldTouched('metadata_prop_azi')}
                    onChange={handleChange('metadata_prop_azi')}
                    options={vesselPropTypeOptions}
                    error={errors.metadata_prop_azi}
                    disabled={isLoading}
                    readOnly={isReadOnly}
                  />
                </Grid>
              }
              {
                fieldEnabled('metadata_rudder') &&
                <Grid item xs={4} style={{marginTop: '15px', position: 'relative'}}>
                  <Select
                    id="vessel-rudder"
                    label="Rudder"
                    value={values.metadata_rudder}
                    onClose={() => setFieldTouched('metadata_rudder')}
                    onChange={handleChange('metadata_rudder')}
                    options={vesselRudderTypeOptions}
                    error={errors.metadata_rudder}
                    disabled={isLoading}
                    readOnly={isReadOnly}
                  />
                </Grid>
              }
              {
                fieldEnabled('metadata_thr_fwd') &&
                <Grid item xs={4} style={{marginTop: '15px', position: 'relative'}}>
                  <div style={{position: 'relative'}}>
                  <TextInput
                    errorFieldAbsolute
                    id="vessel-thrust-fwd"
                    label="Thr. Fwd"
                    unit="kW"
                    value={values.metadata_thr_fwd}
                    error={errors.metadata_thr_fwd}
                    useMissingPlaceholder
                    disabled={isLoading}
                    readOnly={isReadOnly}
                    {...getPropsForAutoformatOnBlur({
                      fieldName: 'metadata_thr_fwd',
                      dp: 2,
                      setFieldValue,
                      setFieldTouched,
                    })}
                    inputProps={{ inputMode: 'decimal' }}
                    subtextAbsolute
                    subtext={
                      isPositive(values.metadata_thr_fwd)
                        ? `(${roundTwoDecimalPlace(kwToHp(parseFloat(values.metadata_thr_fwd)))} HP)` : ''
                    }
                  />
                  </div>
                </Grid>
              }
              {
                fieldEnabled('metadata_thr_aft') &&
                <Grid item xs={4} style={{marginTop: '15px', position: 'relative'}}>
                  <div style={{ position: 'relative' }}>
                    <TextInput
                    errorFieldAbsolute
                      id="vessel-thrust-aft"
                      label="Thr. Aft"
                      unit="kW"
                      value={values.metadata_thr_aft}
                      error={errors.metadata_thr_aft}
                      useMissingPlaceholder
                      disabled={isLoading}
                      readOnly={isReadOnly}
                      {...getPropsForAutoformatOnBlur({
                        fieldName: 'metadata_thr_aft',
                        dp: 2,
                        setFieldValue,
                        setFieldTouched,
                      })}
                      inputProps={{ inputMode: 'decimal' }}
                      subtextAbsolute
                      subtext={
                        isPositive(values.metadata_thr_aft)
                          ? `(${roundTwoDecimalPlace(kwToHp(parseFloat(values.metadata_thr_aft)))} HP)` : ''
                      }
                    />
                  </div>
                </Grid>
              }
              {
                fieldEnabled('metadata_main_engine') &&
                <Grid item xs={4} style={{marginTop: '15px', position: 'relative'}}>
                  <div style={{ position: 'relative' }}>
                    <TextInput
                    errorFieldAbsolute
                      id="vessel-main-engine"
                      label="Main Engine"
                      unit="kW"
                      value={values.metadata_main_engine}
                      error={
                        errors.metadata_main_engine
                      }
                      useMissingPlaceholder
                      disabled={isLoading}
                      readOnly={isReadOnly}
                      {...getPropsForAutoformatOnBlur({
                        fieldName: 'metadata_main_engine',
                        dp: 2,
                        setFieldValue,
                        setFieldTouched,
                      })}
                      inputProps={{ inputMode: 'decimal' }}
                      subtextAbsolute
                      subtext={
                        isPositive(values.metadata_main_engine)
                          ? `(${roundTwoDecimalPlace(kwToHp(parseFloat(values.metadata_main_engine)))} HP)` : ''
                      }
                    />
                  </div>
                </Grid>
              }
              {
                fieldEnabled('metadata_me_power_limitation') &&
                <Grid item xs={4} style={{marginTop: '15px', position: 'relative'}}>
                  <Select
                    id="vessel-me-power-limitation"
                    label="Power limitation"
                    value={values.metadata_me_power_limitation}
                    onClose={() => setFieldTouched('metadata_me_power_limitation')}
                    onChange={handleChange('metadata_me_power_limitation')}
                    options={vesselMEPowerLimitationOptions}
                    error={errors.metadata_me_power_limitation}
                    disabled={isLoading}
                    readOnly={isReadOnly}
                  />
                </Grid>
              }
              {
                fieldEnabled('metadata_me_limited_power') &&
                <Grid item xs={4} style={{marginTop: '15px', position: 'relative'}}>
                  <div style={{position: 'relative'}}>
                  <TextInput
                    errorFieldAbsolute
                    id="vessel-me-limited-power"
                    label="Limited power"
                    unit="kW"
                    value={values.metadata_me_limited_power}
                    error={errors.metadata_me_limited_power}
                    useMissingPlaceholder
                    disabled={isLoading}
                    readOnly={isReadOnly}
                    {...getPropsForAutoformatOnBlur({
                      fieldName: 'metadata_me_limited_power',
                      dp: 2,
                      setFieldValue,
                      setFieldTouched,
                    })}
                    inputProps={{ inputMode: 'decimal' }}
                    subtextAbsolute
                    subtext={isPositive(values.metadata_me_limited_power) ? `(${roundTwoDecimalPlace(kwToHp(parseFloat(values.metadata_me_limited_power)))} HP)` : ''}
                  />
                  </div>
                </Grid>
              }
              {
                fieldEnabled('metadata_me_limiter_immediately_overridable') &&
                <Grid item xs={4} style={{marginTop: '15px', position: 'relative'}}>
                  <Select
                    id="vessel-me-limiter-immediately-overridable"
                    label="Limiter overridable"
                    value={values.metadata_me_limiter_immediately_overridable}
                    onClose={() => setFieldTouched('metadata_me_limiter_immediately_overridable')}
                    onChange={handleChange('metadata_me_limiter_immediately_overridable')}
                    options={vesselMELimiterImmediatelyOverridableOptions}
                    error={errors.metadata_me_limiter_immediately_overridable}
                    disabled={isLoading}
                    readOnly={isReadOnly}
                  />
                </Grid>
              }
              {
                fieldEnabled('bittsFwd') &&
                <Grid item xs={4} style={{marginTop: '15px', position: 'relative'}}>
                  <TextInput
                    errorFieldAbsolute
                    id="vessel-bitts-fwd"
                    label="Bitts (SWL) Fwd."
                    unit="t"
                    value={values.bittsFwd}
                    error={errors.bittsFwd}
                    useMissingPlaceholder
                    disabled={isLoading}
                    readOnly={isReadOnly}
                    {...getPropsForAutoformatOnBlur({
                      fieldName: 'bittsFwd',
                      dp: 2,
                      setFieldValue,
                      setFieldTouched,
                    })}
                    inputProps={{ inputMode: 'decimal' }}
                  />
                </Grid>
              }
              {
                fieldEnabled('bittsAft') &&
                <Grid item xs={4} style={{marginTop: '15px', position: 'relative'}}>
                  <TextInput
                    errorFieldAbsolute
                    id="vessel-bitts-aft"
                    label="Bitts (SWL) Aft"
                    unit="t"
                    value={values.bittsAft}
                    error={errors.bittsAft}
                    useMissingPlaceholder
                    disabled={isLoading}
                    readOnly={isReadOnly}
                    {...getPropsForAutoformatOnBlur({
                      fieldName: 'bittsAft',
                      dp: 2,
                      setFieldValue,
                      setFieldTouched,
                    })}
                    inputProps={{ inputMode: 'decimal' }}
                  />
                </Grid>
              }
              {
                fieldEnabled('trackControl') &&
                <Grid item xs={4} style={{marginTop: '15px', position: 'relative'}}>
                  <Select
                    id="vessel-track-control"
                    label={
                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <span style={{ whiteSpace: 'nowrap' }}>Track Control</span>
                        <Tooltip content={TOOLTIPS.VESSEL_TRACK_CONTROL} />
                      </div>
                    }
                    value={values.trackControl}
                    onClose={() => setFieldTouched('trackControl')}
                    onChange={handleChange('trackControl')}
                    options={vesselTrackControlOptions}
                    error={errors.trackControl}
                    disabled={isLoading}
                    readOnly={isReadOnly}
                    formatValue={value => {
                      const match = vesselTrackControlOptions.find(option => option.value === value)
                      return match ? match.label : value
                    }}
                  />
                </Grid>
              }
              {
                fieldEnabled('metadata_astern_pwr') &&
                <Grid item xs={4} style={{marginTop: '15px', position: 'relative'}}>
                  <TextInput
                    errorFieldAbsolute
                    id="vessel-astern-pwr"
                    label="Astern Power"
                    unit="%"
                    value={values.metadata_astern_pwr}
                    error={
                      errors.metadata_astern_pwr
                    }
                    useMissingPlaceholder
                    disabled={isLoading}
                    readOnly={isReadOnly}
                    {...getPropsForAutoformatOnBlur({
                      fieldName: 'metadata_astern_pwr',
                      dp: 2,
                      setFieldValue,
                      setFieldTouched,
                    })}
                    inputProps={{ inputMode: 'decimal' }}
                  />
                </Grid>
              }
            </Fieldset>

            <Fieldset container spacing={2} style={{alignItems: 'end'}}>
              {
                fieldEnabled('metadata_full_hbr') &&
                <Grid item xs={4} style={{marginTop: '15px', position: 'relative'}}>
                  <TextInput
                    errorFieldAbsolute
                    id="vessel-full-hbr"
                    label="Full Ahead"
                    unit="kn"
                    value={values.metadata_full_hbr}
                    error={errors.metadata_full_hbr}
                    useMissingPlaceholder
                    disabled={isLoading}
                    readOnly={isReadOnly}
                    {...getPropsForAutoformatOnBlur({
                      fieldName: 'metadata_full_hbr',
                      dp: 2,
                      setFieldValue,
                      setFieldTouched,
                    })}
                    inputProps={{ inputMode: 'decimal' }}
                  />
                </Grid>
              }

              {
                fieldEnabled('metadata_half_ahead') &&
                <Grid item xs={4} style={{marginTop: '15px', position: 'relative'}}>
                  <TextInput
                    errorFieldAbsolute
                    id="vessel-half-ahead"
                    label="Half Ahead"
                    unit="kn"
                    value={values.metadata_half_ahead}
                    error={
                      errors.metadata_half_ahead
                    }
                    useMissingPlaceholder
                    disabled={isLoading}
                    readOnly={isReadOnly}
                    {...getPropsForAutoformatOnBlur({
                      fieldName: 'metadata_half_ahead',
                      dp: 2,
                      setFieldValue,
                      setFieldTouched,
                    })}
                    inputProps={{ inputMode: 'decimal' }}
                  />
                </Grid>

              }

              {
                fieldEnabled('metadata_slow_ahead') &&
                <Grid item xs={4} style={{marginTop: '15px', position: 'relative'}}>
                  <TextInput
                    errorFieldAbsolute
                    id="vessel-slow-ahead"
                    label="Slow Ahead"
                    unit="kn"
                    value={values.metadata_slow_ahead}
                    error={
                      errors.metadata_slow_ahead
                    }
                    useMissingPlaceholder
                    disabled={isLoading}
                    readOnly={isReadOnly}
                    {...getPropsForAutoformatOnBlur({
                      fieldName: 'metadata_slow_ahead',
                      dp: 2,
                      setFieldValue,
                      setFieldTouched,
                    })}
                    inputProps={{ inputMode: 'decimal' }}
                  />
                </Grid>

              }

              {
                fieldEnabled('metadata_dead_slow') &&
                <Grid item xs={4} style={{marginTop: '15px', position: 'relative'}}>
                  <TextInput
                    errorFieldAbsolute
                    id="vessel-dead-slow"
                    label="Dead Slow"
                    unit="kn"
                    value={values.metadata_dead_slow}
                    error={
                      errors.metadata_dead_slow
                    }
                    useMissingPlaceholder
                    disabled={isLoading}
                    readOnly={isReadOnly}
                    {...getPropsForAutoformatOnBlur({
                      fieldName: 'metadata_dead_slow',
                      dp: 2,
                      setFieldValue,
                      setFieldTouched,
                    })}
                    inputProps={{ inputMode: 'decimal' }}
                  />
                </Grid>
              }

              {
                fieldEnabled('metadata_sea_speed') &&
                <Grid item xs={4} style={{marginTop: '15px', position: 'relative'}}>
                  <TextInput
                    errorFieldAbsolute
                    id="vessel-sea-speed"
                    label="Sea Speed"
                    unit="kn"
                    value={values.metadata_sea_speed}
                    error={
                      errors.metadata_sea_speed
                    }
                    useMissingPlaceholder
                    disabled={isLoading}
                    readOnly={isReadOnly}
                    {...getPropsForAutoformatOnBlur({
                      fieldName: 'metadata_sea_speed',
                      dp: 2,
                      setFieldValue,
                      setFieldTouched,
                    })}
                    inputProps={{ inputMode: 'decimal' }}
                  />
                </Grid>
              }

              {
                fieldEnabled('metadata_squat_full_hbr') &&
                <Grid item xs={4} style={{marginTop: '15px', position: 'relative'}}>
                  <TextInput
                    errorFieldAbsolute
                    id="vessel-squat-full-hbr"
                    label="Squat at full Harbour Speed"
                    unit="m"
                    value={values.metadata_squat_full_hbr}
                    error={
                      errors.metadata_squat_full_hbr
                    }
                    useMissingPlaceholder
                    disabled={isLoading}
                    readOnly={isReadOnly}
                    {...getPropsForAutoformatOnBlur({
                      fieldName: 'metadata_squat_full_hbr',
                      dp: 2,
                      setFieldValue,
                      setFieldTouched,
                    })}
                    inputProps={{ inputMode: 'decimal' }}
                  />
                </Grid>
              }
            </Fieldset>

            <Fieldset container spacing={2} style={{alignItems: 'end'}}>
              {
                fieldEnabled('metadata_mmsi') &&
                <Grid item xs={4} style={{marginTop: '15px', position: 'relative', marginBottom: '10px'}}>
                  <Field
                    name="metadata_mmsi"
                    render={({ field }) => (
                      <TextInput
                        errorFieldAbsolute
                        id="vessel-mmsi"
                        label="MMSI"
                        formikField={field}
                        error={errors.metadata_mmsi}
                        useMissingPlaceholder
                        disabled={isLoading}
                        readOnly={isReadOnly}
                      />
                    )}
                  />
                </Grid>
              }

              {
                fieldEnabled('metadata_year_built') &&
                <Grid item xs={4} style={{marginTop: '15px', position: 'relative', marginBottom: '10px'}}>
                  <Field
                    name="metadata_year_built"
                    render={({ field }) => (
                      <TextInput
                    errorFieldAbsolute
                        id="vessel-year-built"
                        label="Year Built"
                        formikField={field}
                        error={
                          errors.metadata_year_built
                        }
                        inputProps={{ inputMode: 'numeric' }}
                        useMissingPlaceholder
                        disabled={isLoading}
                        readOnly={isReadOnly}
                      />
                    )}
                  />
                </Grid>
              }
              {
                fieldEnabled('metadata_call_sign') &&
                <Grid item xs={4} style={{marginTop: '15px', position: 'relative', marginBottom: '10px'}}>
                  <Field
                    name="metadata_call_sign"
                    render={({ field }) => (
                      <TextInput
                    errorFieldAbsolute
                        id="vessel-callsign"
                        label="Callsign"
                        formikField={field}
                        error={
                          errors.metadata_call_sign
                        }
                        useMissingPlaceholder
                        disabled={isLoading}
                        readOnly={isReadOnly}
                      />
                    )}
                  />
                </Grid>
              }
            </Fieldset>

            <Fieldset container spacing={2}>
              {
                fieldEnabled('metadata_comments') &&
                <Grid item xs={12}>
                  <Box display="flex" mb={1} mt={1}>
                    <CommentLabel htmlFor="vessel-comments">
                      <strong>Last Pilot(s) Remarks</strong>
                      <span> (Update or delete as required)</span>
                    </CommentLabel>
                  </Box>
                  <Field
                    name="metadata_comments"
                    render={() => (
                      <FreeTextField
                        id="vessel-comments"
                        value={values.metadata_comments}
                        placeholder="Enter comments here"
                        onChange={handleChange('metadata_comments')}
                        error={errors.comments}
                        disabled={
                          isLoading ||
                          !isAssigned ||
                          isPilotageArchived ||
                          isPilotageCancelled
                        }
                        readOnly={
                          !isAssigned || isPilotageArchived || isPilotageCancelled
                        }
                      />
                    )}
                  />
                </Grid>
              }
            </Fieldset>

            <Box
              mt={4}
              display="flex"
              justifyContent="flex-end"
              alignItems="flex-end"
            >
              {
                hasErrors &&
                <ErrorSummary>Please address {errorCount} error{errorCount > 1 ? 's' : ''} above</ErrorSummary>
              }

              <PrimaryButton
                type="submit"
                disabled={
                  !hasUnsavedChanges ||
                  !isAssigned ||
                  hasErrors ||
                  isSubmitting
                }
              >
                Update
              </PrimaryButton>
            </Box>
          </Form>
        )
      }}
    </Formik>
  )
}

EditVesselDetails.propTypes = {}

export default EditVesselDetails
