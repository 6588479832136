import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import { Box, Menu, MenuItem } from '@material-ui/core'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import logo from 'src/assets/images/empx-logo.png'
import { usernameSelector } from 'src/store/auth/selectors'
import { requestLogout } from 'src/store/auth/actions'
import { portsSelector, selectedPortSelector } from 'src/store/ports/selectors'
import { setSelectedPort } from 'src/store/ports/actions'
import ExternalLink from 'src/components/atoms/ExternalLink'
import { externalLink1Selector, externalLink2Selector, externalLink3Selector } from 'src/store/extras/selectors'
import useSync from 'src/hooks/useSync'
import { SYNC_STATUS } from 'src/utils/constants'
import { pilotageFilterSelector } from 'src/store/pilotageList/selectors'
import { setFilter } from 'src/store/pilotageList/actions'
import useOnlineStatus from 'src/hooks/useOnlineStatus'

export const HeaderSection = styled.div`
  display: flex;
  align-items: center;
  padding: ${({ theme }) => `${theme.spacing(2)}px ${theme.spacing(3)}px`};
  background-color: ${({ theme }) => theme.palette.background.light};
  justify-content: space-between;
`

const HeaderLogo = styled.img`
  height: 22px;
  display: block;
`

const DropdownWrapper = styled.div(
  ({ theme, isStatic, isDisabled }) => css`
    display: flex;
    align-items: center;
    cursor: ${isStatic || isDisabled ? 'default' : 'pointer'};
    color: ${theme.palette.text.primary};
    opacity: ${isDisabled ? 0.3 : 1};
    & .MuiSvgIcon-root {
      display: block;
    }
  `
)

const DropdownTitle = styled.span`
  font-weight: ${({ theme }) => theme.weights.fontWeightMedium};
`

const IconWrapper = styled.span`
  margin-left: ${({ theme }) => theme.spacing(1)}px;
  color: ${({ theme }) => theme.palette.primary.main};
`

const StyledMenu = styled(Menu)`
  & .MuiPopover-paper {
    margin-top: 8px;
    margin-left: 5px;
    border: 1px solid white;
    overflow: visible;
    &::before {
      content: '';
      position: absolute;
      top: -12px;
      right: 10px;
      border-bottom: 6px solid white;
      border-right: 6px solid transparent;
      border-left: 6px solid transparent;
      border-top: 6px solid transparent;
      z-index: 10;
    }
  }
  & ul {
    padding: 0;
    min-width: 200px;
  }
  & li {
    color: ${({ theme }) => theme.palette.text.dark};
    font-size: ${({ theme }) => theme.typography.pxToRem(14)};
    font-weight: ${({ theme }) => theme.weights.fontWeightSemiBold};
    text-transform: uppercase;
    margin: 0;
    padding: 8px 16px;
    &:not(:first-child) {
      border-top: 1px solid #ededed;
    }
  }
`

const AppHeader = ({ disablePortSelector }) => {
  const { stat } = useSync()
  const dispatch = useDispatch()

  const externalLink1 = useSelector(externalLink1Selector)
  const externalLink2 = useSelector(externalLink2Selector)
  const externalLink3 = useSelector(externalLink3Selector)
  const isOnline = useOnlineStatus()

  const username = useSelector(usernameSelector)
  const userMenuTrigger = useRef(null)
  const [userMenuAnchor, setUserMenuAnchor] = useState(null)

  const { data: ports } = useSelector(portsSelector)
  const selectedPort = useSelector(selectedPortSelector)
  const userHasMultiplePorts = ports && ports.length > 1

  const portMenuTrigger = useRef(null)
  const [portMenuAnchor, setPortMenuAnchor] = useState(null)

  const savedFilter = useSelector(pilotageFilterSelector)

  const toggleUserMenu = () => {
    setUserMenuAnchor(anchor => (anchor ? null : userMenuTrigger.current))
  }

  const togglePortMenu = () => {
    if (userHasMultiplePorts) {
      setPortMenuAnchor(anchor => (anchor ? null : portMenuTrigger.current))
    }
  }

  const logOut = () => {
    closeUserMenu()
    const totalPending = stat[SYNC_STATUS.PENDING]
    if (totalPending) {
      const proceed = window.confirm('Discard unsaved changes?')
      if (proceed) {
        dispatch(requestLogout())
      }
    } else {
      dispatch(requestLogout())
    }
  }

  const visitPrivacyStatement = () => {
    window.open(
      `https://www.trelleborg.com/en/marine-and-infrastructure/privacy-notice`,
      `Master Pilot Exchange Privacy Statement`,
      'menubar=no,location=yes,resizable=yes,scrollbars=no,status=yes'
    )
  }

  const closeUserMenu = () => setUserMenuAnchor(null)
  const closePortMenu = () => setPortMenuAnchor(null)

  const selectPort = port => {
    dispatch(setSelectedPort(port, port.organisation))
    closePortMenu()
    // reset pagination to first page
    dispatch(setFilter({ ...savedFilter, page: 1 }))
  }

  const portsSorted = ports && ports.sort((i1, i2) => i1.name.trim().toLowerCase() < i2.name.trim().toLowerCase() ? -1
    : i1.name.trim().toLowerCase() > i2.name.trim().toLowerCase() ? 1 : 0)

  return (
    <HeaderSection>
      <Box flex="0 0 160px">
        <HeaderLogo src={logo} />
      </Box>
      <Box display="flex" flex={1} justifyContent="flex-end">
        {externalLink1 && (
          <Box mr={1} display="grid">
            <ExternalLink externalLink={externalLink1} />
          </Box>
        )}
        {externalLink2 && (
          <Box mr={1} display="grid">
            <ExternalLink externalLink={externalLink2} />
          </Box>
        )}
        {externalLink3 && (
          <Box mr={6} display="grid">
            <ExternalLink externalLink={externalLink3} />
          </Box>
        )}
        <DropdownWrapper
          isDisabled={disablePortSelector || !isOnline}
          isStatic={!userHasMultiplePorts}
        >
          <Box
            display="flex"
            onClick={!disablePortSelector && isOnline ? togglePortMenu : undefined}
            ref={portMenuTrigger}
            mr={6}
          >
            <DropdownTitle>{selectedPort && selectedPort.name}</DropdownTitle>
            {userHasMultiplePorts && (
              <IconWrapper>
                <KeyboardArrowDownIcon color="primary" />
              </IconWrapper>
            )}
          </Box>
          {portsSorted && portsSorted.length > 1 && (
            <StyledMenu
              open={!!portMenuAnchor}
              anchorEl={portMenuAnchor}
              onClose={closePortMenu}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              getContentAnchorEl={null}
            >
              {portsSorted.map(port => (
                <MenuItem key={port.uuid} onClick={() => selectPort(port)}>
                  {port.name}
                </MenuItem>
              ))}
            </StyledMenu>
          )}
        </DropdownWrapper>
        <DropdownWrapper>
          <Box display="flex" onClick={toggleUserMenu} ref={userMenuTrigger}>
            <DropdownTitle>{username || 'Account'}</DropdownTitle>
            <IconWrapper>
              <KeyboardArrowDownIcon color="primary" />
            </IconWrapper>
          </Box>
          <StyledMenu
            open={!!userMenuAnchor}
            anchorEl={userMenuAnchor}
            onClose={closeUserMenu}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            getContentAnchorEl={null}
          >
            <MenuItem onClick={visitPrivacyStatement}>
              Privacy Statement
            </MenuItem>
            <MenuItem onClick={logOut}>Log Out</MenuItem>
          </StyledMenu>
        </DropdownWrapper>
      </Box>
    </HeaderSection>
  )
}

AppHeader.propTypes = {
  disablePortSelector: PropTypes.bool,
}

export default React.memo(AppHeader)
