import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { InputAdornment, TextField } from '@material-ui/core'

import FormFieldLabel from 'src/components/atoms/FormFieldLabel'
import FormFieldError from 'src/components/atoms/FormFieldError'
import { missingValueText } from 'src/utils/constants'

const OVERRIDE_COLOUR = '#FFD24F'
const Circle = styled.div`
  width: 10px;
  height: 10px;
  background: ${({ theme }) => theme.palette.primary.main};
  border-radius: 5px;
  margin: 7px;
  float: right;
`

const StyledTextField = styled(({ overrideColour, errorOutline, ...rest }) => (
  <TextField {...rest} />
))`

  & .MuiOutlinedInput-root {
    border-color: ${({ readOnly, errorOutline, theme }) =>
      readOnly
        ? theme.palette.readOnly
        : errorOutline
        ? theme.palette.error.main
        : ''};
      color: ${({ overrideColour, theme }) => overrideColour ? OVERRIDE_COLOUR : theme.palette.text.primary};
      border-radius: ${({ subtext }) =>
        subtext ? '4px 4px 0px 4px' : '4px'
      };
    }

  & .invalid {
    border-color: ${({ theme }) => theme.palette.error.main};
  }

  & .MuiInputAdornment-root > p {
    font-size: ${({ theme }) => theme.typography.pxToRem(18)};
  }

  & .MuiSelect-select {
    height: 1.1875em; // match mui text input
    color: ${({ value, theme }) =>
      value ? theme.palette.text.primary : theme.palette.text.secondary};
  }
`

const SuccessOutlineTextField = styled(StyledTextField)`
  && .MuiOutlinedInput-root {
    border-color: ${({ theme }) => theme.palette.success.main};
    color: ${({ overrideColour, theme }) => overrideColour ? OVERRIDE_COLOUR : theme.palette.text.primary};
  }
`

const ErrorOutlineTextField = styled(StyledTextField)`
  && .MuiOutlinedInput-root {
    border-color: ${({ theme }) => theme.palette.error.main};
    color: ${({ overrideColour, theme }) => overrideColour ? OVERRIDE_COLOUR : theme.palette.text.primary};
  }
  `

const SubText = styled.div`
  color: #fff;
  float: right;
  font-size: 11px;
  background: ${({ readOnly, errorOutline, theme }) =>
    readOnly
      ? '#485466'
      : errorOutline
      ? theme.palette.error.main
      : '#1f8efa'
  };
  line-height: 12px;
  padding: 1px 3px 1px 3px;
  top: 0px;
  position: relative;
  border-radius: 0px 0px 3px 3px;
`

const TextInput = ({
  id,
  label,
  error,
  disabled,
  readOnly,
  onChange,
  className,
  unit,
  formikField,
  useMissingPlaceholder,
  value,
  InputProps,
  errorOutline,
  successOutline,
  overrideColour,
  active,
  subtext,
  subtextAbsolute,
  errorFieldAbsolute,
  ...otherProps
}) => {
  const onTextFieldChange = useCallback(
    e => {
      onChange && onChange(e.target.value)
    },
    [onChange]
  )

  const inputId = `input-${id}`

  let StyledInput
  if (errorOutline) {
    StyledInput = ErrorOutlineTextField
  } else if (successOutline) {
    StyledInput = SuccessOutlineTextField
  } else {
    StyledInput = StyledTextField
  }

  return (
    <div className={className}>
      {label && (
        <FormFieldLabel
          disabled={disabled}
          readOnly={readOnly}
          htmlFor={inputId}
        >
          {label}
        </FormFieldLabel>
      )}

      {active && <Circle />}

      <StyledInput
        readOnly={readOnly}
        errorOutline={errorOutline}
        variant="outlined"
        disabled={disabled}
        onChange={onTextFieldChange}
        value={value}
        overrideColour={overrideColour}
        placeholder={useMissingPlaceholder ? missingValueText : ''}
        InputProps={{
          classes: {
            input: error ? 'invalid' : ''
          },
          endAdornment: unit && (
            <InputAdornment position="end">{unit}</InputAdornment>
          ),
          id: inputId,
          readOnly,
          ...InputProps,
          ...formikField,
        }}
        subtext={subtext}
        {...otherProps}
      />
      {subtext && !subtextAbsolute && <SubText readOnly={readOnly} errorOutline={errorOutline}>{subtext}</SubText>}
      {subtext && subtextAbsolute && <SubText style={{position: 'absolute', top: "auto", right: 0}} readOnly={readOnly} errorOutline={errorOutline}>{subtext}</SubText>}
      {error && errorFieldAbsolute &&  <FormFieldError style={{position: 'absolute'}}>{error}</FormFieldError>}
      {error && !errorFieldAbsolute &&  <FormFieldError>{error}</FormFieldError>}
    </div>
  )
}

TextInput.propTypes = {
  id: PropTypes.string,
  error: PropTypes.string,
  label: PropTypes.any,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.string,
  unit: PropTypes.string,
  formikField: PropTypes.object,
  useMissingPlaceholder: PropTypes.bool,
  InputProps: PropTypes.object,
  value: PropTypes.any,
  successOutline: PropTypes.bool,
  errorOutline: PropTypes.bool,
  overrideColour: PropTypes.bool,
  active: PropTypes.bool,
  subtext: PropTypes.string,
  subtextAbsolute: PropTypes.bool,
  errorFieldAbsolute: PropTypes.bool,
}

export default React.memo(TextInput)
